import React, { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';

import { Text } from 'components';
import CircleTick from 'assets/icons/payments/circle-tick.svg';
import CircleChecked from 'assets/icons/payments/circle-checked.svg';
import CircleUnchecked from 'assets/icons/payments/circle-unchecked.svg';
import theme from 'utils/theme';
import { tablet } from 'styles/breakpoints';

interface PaymentStepsProps {
  firstStepTitle: string;
  secondStepTitle: string;
  thirdStepTitle: string;
  optionalStepTitle?: string;
  hasOptionalStep?: boolean;
}

interface DotDividerProps {
  hasOpacity?: boolean;
}

const Container = styled.div`
  display: flex;
  max-width: 28.75rem;
`;

const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DotTitle = styled(Text)`
  color: #1c1c28;
  text-align: center;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 140%;
  margin-top: 0.25rem;
  @media ${tablet} {
    font-size: 0.5625rem;
    line-height: normal;
  }
`;
const DotDividerContainer = styled.div`
  flex: 1;
  height: 24px;
  display: flex;
  align-items: center;
`;

const DotDivider = styled.div<DotDividerProps>`
  flex: 1;
  height: 1px;
  background-color: #6a6a8c;
  opacity: ${({ hasOpacity }) => (hasOpacity ? 0.25 : 1)};
`;

const PaymentSteps: FC<PaymentStepsProps & HTMLAttributes<HTMLDivElement>> = ({
  firstStepTitle,
  secondStepTitle,
  thirdStepTitle,
  optionalStepTitle,
  hasOptionalStep,
  ...props
}) => {
  const renderDot = (dot?: string) => {
    switch (dot) {
      case 'tick':
        return <CircleTick fill="#6A6A8C" width={24} height={24} />;
      case 'checked':
        return <CircleChecked fill="#6A6A8C" width={24} height={24} />;
      default:
        return <CircleUnchecked fill="#6A6A8C" width={24} height={24} />;
    }
  };

  return (
    <Container {...props}>
      <StepContainer>
        {renderDot(firstStepTitle?.status)}
        <DotTitle>{firstStepTitle?.text}</DotTitle>
      </StepContainer>
      <DotDividerContainer>
        <DotDivider />
      </DotDividerContainer>
      <StepContainer>
        {renderDot(secondStepTitle?.status)}
        <DotTitle>{secondStepTitle?.text}</DotTitle>
      </StepContainer>
      <DotDividerContainer>
        <DotDivider hasOpacity />
      </DotDividerContainer>
      {optionalStepTitle ? (
        <>
          <StepContainer>
            {renderDot(optionalStepTitle?.status)}
            <DotTitle>{optionalStepTitle?.text}</DotTitle>
          </StepContainer>
          <DotDividerContainer>
            <DotDivider hasOpacity />
          </DotDividerContainer>
        </>
      ) : null}
      <StepContainer>
        {renderDot(thirdStepTitle?.status)}
        <DotTitle>{thirdStepTitle?.text}</DotTitle>
      </StepContainer>
    </Container>
  );
};

export default PaymentSteps;
