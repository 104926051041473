import React, { FC } from 'react';
import styled from 'styled-components';
import CardContainer from './CardContainer';
import TickIcon from 'assets/icons/tick-circle.svg';

import { Text } from 'components';
import { smMobile, tablet } from 'styles/breakpoints';
import { ImageWithContainer } from '..';

interface ListProps {
  title: string;
  list: string[];
  padding?: string;
  cardBgColor?: string;
  maxWidth?: string;
}

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background: #fff;
  padding: 4rem 1rem;
  @media ${tablet} {
    padding: 3rem 1rem;
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  max-width: 30.5rem;
`;

const Title = styled(Text)`
  color: #000;
  text-align: center;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem;
  padding-bottom: 1.5rem;
  @media ${tablet} {
  }
`;

const ListItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: 36.7rem;
  margin-bottom: 0.75rem;
`;

const Item = styled(Text)`
  color: #28293d;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.011rem;
  padding-left: 1rem;
`;

const CardContainerStyled = styled(CardContainer)`
  border-radius: 1rem;
  border: 1px solid #e6e6f1;
  background: #f3f3f8;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.05);
  padding: 1.5rem;
  margin: 0;
  @media ${tablet} {
    padding: 1.5rem 1.78rem;
  }
`;

const Circle = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 3.8265rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3.125rem;
  background: #f7c800;
`;

const Number = styled(Text)`
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const IconContainer = styled.div`
  width: 1.5rem;
  height: 1.5rem;
`;

const ListItem = ({ item, number }: { item: string; number: number }) => (
  <ListItemContainer>
    <IconContainer>
      <Circle>
        <Number>{number}</Number>
      </Circle>
    </IconContainer>
    <Item dangerouslySetInnerHTML={{ __html: item }} />
  </ListItemContainer>
);

const NumberedList: FC<ListProps> = ({
  title,
  list,
  padding = '0',
  cardBgColor,
  maxWidth,
  ...props
}) => {
  const renderListItem = (item: string, index: number) => (
    <ListItem item={item} key={index} number={index + 1} />
  );
  return (
    <Container {...props}>
      <ContentContainer>
        <CardContainerStyled>
          <Title>{title}</Title>
          {list?.map(renderListItem)}
        </CardContainerStyled>
      </ContentContainer>
    </Container>
  );
};

export default NumberedList;
